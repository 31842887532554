import { useState } from "react";
import {UserDetailAction} from "../actiontypes/account"

const initialState = {
    data:null,
    isLoading:false,
    isSuccess:false,
    isError:false
}

const UserDetailsReducer = (state=initialState, action) => {
    switch (action.type) {
        case UserDetailAction.success:
            return {...state, 
                data: action.payload, 
                isSuccess: true,
                isLoading: false,
                isError: false
            };
            break;
        case UserDetailAction.loading:
            return {...state,
                isSuccess: false,
                isLoading: true,
                isError: false
            }
            break;
        case UserDetailAction.error:
            return {...state,
                data: action.payload,
                isSuccess: false,
                isLoading: false,
                isError: true
            }
            break;
        default:
            return {...state}
            break;
    }
}

export default UserDetailsReducer


