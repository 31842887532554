import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../plugins/helpers";
const NoAccessPage: FC = () => {

    const { user,logout } = useAuth0()
    return (
        <>


           

<div
      className='w-100 h-100 bg-primary'
     
    >
      <div className='d-flex flex-column flex-center '>
        
        <div className='d-flex flex-column flex-center flex-column-fluid'>
          {/*begin::Content */}
          <div className='d-flex flex-column flex-center text-center p-10 '>
            {/*begin::Wrapper */}
            <div className='card card-flush w-lg-650px py-5'>
              <div className='card-body py-15 py-lg-20'>
                {/*begin::Logo */}
                <div className='mb-14'>
                  <a href='/'>
                    <img
                      alt='Logo'
                      //src='assets/media/logos/custom-2.svg'
                      src={toAbsoluteUrl('/media/logos/PurifAI_Logo-2.png')}
                      className='w-200px '
                    />
                  </a>
                </div>
               
                <h1 className='fw-bolder text-gray-900 mb-5'>Your account is not accessible.</h1>
            
                <div className='fs-6 mb-8'>
                  <span className='fw-semibold text-gray-900'>
                  Kindly reach out to your account owner.
                  </span>
                </div>
               
            
                <div className='mb-11' 
                >
                  <div onClick={()=>logout({returnTo: `https://auth.${process.env.REACT_APP_BASE_DOMAIN}`})} className='btn btn-sm btn-primary'>
                    Home
                  </div>
                </div>
                {/*end::Link */}
                {/*begin::Illustration */}
                <div className='mb-0'>
                  <img
                    src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/20-dark.png')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                  />
                 
                </div>
               
              </div>
            </div>
            
          </div>
          
        </div>
       
      </div>
     
    </div>
        </>
    )
}

export { NoAccessPage }