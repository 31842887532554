//import axios from "axios";
import setting from '../../../config/apiConfig/globalConfig'
import {MessageDetailAction} from '../actiontypes/message'
import {useAuth0} from '@auth0/auth0-react'
import axios from '../../../config/axiosConfig/axiosinterceptor'

export const success = (payload) => {
  return {
    type: MessageDetailAction.messageSuccess,
    payload: payload,
  }
}

export const loading = () => {
  return {
    type: MessageDetailAction.messageLoading,
  }
}

export const errors = (errors) => {
  return {
    type: MessageDetailAction.messageLoading,
    payload: errors,
  }
}

export const fetchMessageDetails = (email,isAdmin) => (dispatch) => {
  dispatch(loading())

  axios
    .post(`/message/notifycount`, {email: email,isAdmin})
    .then(({data}) => {
      dispatch(success(data))
    })
    .catch((err) => {
      dispatch(errors(err.message))
    })
}
// export const fetchMessageDetailsFromSocket = (inboxCount) => (dispatch) => {
//   dispatch(loading())
//   dispatch(success(inboxCount)) 
// }
// export const fetchMessageDetailsFun = (email) => () => {

//   axios
//     .post(`/message/notifycount`, {email: email})
//     .then(({data}) => {
//      console.log("data from message", data)
//     })
//     .catch((err) => {
     
//     })
// }