import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import WebPushNotification from "./custom/component/webPushNotification";
import { useSelector } from "react-redux";
import { RootState } from "../stores/store";
import { IOwner, IUserData } from "../stores/reducerInterface/account";
import jwtDecode, {JwtPayload} from 'jwt-decode'
const Auth0IdleLogout: FC = ({ children }) => {

  const { isAuthenticated, isLoading, logout, getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();

  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(true);
  const [token, setToken] = useState<string>('');

  document.addEventListener("visibilitychange", () => {

    if (document.hidden) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  });

  const fetchToken = (val?:number) => {
    try {
      console.log("started fetch",val)
      let time = val? val: 25;
      console.log({time})
      setTimeout(()=>{
        getAccessTokenSilently({ ignoreCache: true }).then(res=>fetchToken(time)).catch((err: any) => console.log({ err }, "fetch Error"));
        
      },time*60*1000);
    } catch (error) {
      console.log({ error });
    }
  }
  const fetchTokenWithRetry = async(retries = 3, delay = 300) => {
    let attempt = 0;
  
    while (attempt < retries) {
        try {
           const token = await getAccessTokenSilently({ignoreCache: true,audience: process.env.REACT_APP_AUDIENCE});
    
            return token;
  
        } catch (error) {
            if (attempt === retries - 1) {
                throw new Error('All attempts to fetch token failed.');
            }
            await new Promise((resolve) => setTimeout(resolve, delay));
            attempt++;
        }
    }
  }

  const timeout: any = useRef();
  useEffect(() => {
    const checkSessionTimeout = async () => {
      console.log("Sesson Timeout started")
      if (isAuthenticated && !isLoading) {
        try {
          const idToken: any = await getIdTokenClaims({});
          console.log({idToken})
          const tokenFromAuth0 = idToken.exp - idToken.iat; // Calculate the remaining time until the token expiration

          if (timeout.current) {
            clearTimeout(timeout.current)
          }

          timeout.current = setTimeout(async () => {
            const url = ['superadmin','admin'].includes(user?.role)? `https://auth.${process.env.REACT_APP_BASE_DOMAIN}` : window.location.origin
            if (isActive) {
              WebPushNotification({
                title: "Session timeout notification",
                options: {
                  body: `Hi ${idToken?.firstName.concat(" ",idToken?.lastName || "")}, Your PurifAI session will end in one minute.`
                }
              })
              let timerInterval: any;
              await Swal.fire({
                heightAuto:false,
                title: "Session timeout notification",
                html: 'Your session is about to expire.<br/>Redirecting in <strong></strong> seconds.',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Stay Connected",
                cancelButtonText: "Logout",
                timer: 60000,
                didOpen: () => {
                  const content = Swal.getHtmlContainer() as any;
                  timerInterval = setInterval(() => {
                    const data: any = content.querySelector('strong')
                    data.textContent = (Swal.getTimerLeft() as any / 1000)
                      .toFixed(0)
                  }, 100)
                },
                willClose: () => {
                  clearInterval(timerInterval)
                },
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const getToken = await fetchTokenWithRetry(2,400);
                  // const getToken =  await getIdTokenClaims();
                  // console.log({getToken});
                  if (token) {
                    setToken("");

                  } else {
                    setToken(idToken?.email || "email");
                  }
                } else {
                  document.cookie=""
                  sessionStorage.clear()
                  localStorage.clear()
                  logout({
                    returnTo:url + '/session/logout',
                  });
                }
              }).catch(err => console.log(err.message))
            } else {
              document.cookie=""
              sessionStorage.clear()
              localStorage.clear()
              logout({
                returnTo: url + '/session/logout',
              });
            }
          },
          tokenFromAuth0 * 1000 );


        } catch (error) {
          console.error('Error checking session timeout:', error);
          window.location.href=`${window.location.origin}/auth/login`
        }
      }
    };

    checkSessionTimeout();
  }, [isAuthenticated, isLoading, user, location, isActive, token]);
  useEffect(()=>{
    getAccessTokenSilently().then((res:any)=>{
     let token= jwtDecode<JwtPayload>(res)
     if(token.exp && token.iat){
      fetchToken(((token?.exp-token?.iat)/60)-4);
     }
    }).catch(err=>console.log({err}))
  },[])

  if (!isAuthenticated) {
    navigate("/")
    return null;
  }
  return (
    <>{children}</>
  )
}

export { Auth0IdleLogout }