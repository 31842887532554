import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Auth0Provider} from '@auth0/auth0-react'
const Auth0ProviderWithHistory = ({children}: any) => {
  const navigate = useNavigate()
  const onRedirectCallback = (appstate: any) => {
    navigate(appstate?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      cacheLocation='memory'
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_CLIENTID as string}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={process.env.REACT_APP_AUDIENCE as string}
      scope='openid profile email'
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  )
}
export default Auth0ProviderWithHistory
