import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect, useState, createContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores/store";
import { fetchMessageDetails } from "../stores/action/message";
import { fetchUserDetails } from "../stores/action/account";
import roles from "../utilies/custom/functions/Roles";

export const SocketContext = createContext<WebSocket | null>(null);

const Socket: FC = ({ children }) => {
    const dispatch = useDispatch();
    const {  isAuthenticated } = useAuth0();
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isReconnect, setIsReconnect] = useState(false);

    const userdetails = useSelector((state: RootState) => state.userdetails);
    const userData = userdetails?.data?.data;
    const [userEmail, setUserEmail] = useState("");


    const handleOpen = (data: Event) => {
        const payload = {
            action: 'setName',
            userEmail: userData?.userDetails?.email,
            userId: userData?.userDetails?._id,
            account: window.location.hostname.split(".")[0]
        };
        console.log(`Working inside Open`, { payload });
        socket?.send(JSON.stringify(payload));
    };

    const handleMessage = (message: MessageEvent) => {
        console.log(`Received message: ${message.data}`);
        const data = JSON.parse(message.data);
        if (data.members) {
            console.log(data.members, 'Data Members');
        } else if (data.publicMessage) {
            console.log(data.publicMessage);
        } else if (data.privateMessage) {
            console.log(data.privateMessage);
        } else if (data.systemMessage) {
            console.log(data.systemMessage);
        } else if (data.inboxCount) {
            console.log(data.inboxCount);
        } else if (data.account==window.location.hostname.split(".")[0]) {
            console.log('inbox user account', data.account);
            dispatch<any>(fetchMessageDetails(userData?.userDetails?.email, roles.app.allSystemRole.includes(userData?.userDetails?.role)));
        } else if (data.camerasImportedEmail) {
            console.log('camera imported', data.camerasImportedEmail);
            dispatch<any>(fetchUserDetails(data.camerasImportedEmail));
        }
    };

    const handleClose = () => {
        setIsReconnect(true);
    };

    useEffect(() => {
        if (isAuthenticated && userData?.userDetails?.email && [...roles.app.allSystemRole, ...roles.app.accountAdmin].includes(userData?.userDetails?.role)) {
            if (!socket || socket.readyState === WebSocket.CLOSED) {
                const connectionUrl = new WebSocket(process.env.REACT_APP_SOCKET_WEBURL || "wss://xw6bi1kap8.execute-api.us-east-1.amazonaws.com/development/");
                console.log("socket working");
                setSocket(connectionUrl);
                setIsReconnect(false);
            }
        }
        return () => {
            socket?.close();
        };
    }, [isReconnect,userEmail]);

    useEffect(() => {
        if (userdetails.isSuccess) {
            setUserEmail(userData?.userDetails?.email || "");
        }
    }, [userdetails.isSuccess]);

    useEffect(() => {
        if (socket) {
            socket.addEventListener('open', handleOpen);
            socket.addEventListener('message', handleMessage);
            socket.addEventListener('close', handleClose);

            return () => {
                socket.removeEventListener('open', handleOpen);
                socket.removeEventListener('message', handleMessage);
                socket.removeEventListener('close', handleClose);
            };
        }
    }, [socket, userData, dispatch]);

    const socketValue = useMemo(() => socket, [socket]);

    return (
        <SocketContext.Provider value={socketValue}>
            {children}
        </SocketContext.Provider>
    );
};

export { Socket };
