import axios from 'axios'
import Settings from '../apiConfig/globalConfig';
import axiosRetry from 'axios-retry';
const apiUrl = Settings.API_ROOT
const instance = axios.create({
  baseURL: `${apiUrl}`,
})
let accessToken = ''
axiosRetry(instance, {
  retries: 1,
  async retryCondition(error) {
    switch (error?.response?.status) {
      case 403:
      case 502:
        return true;
      default:
        return false;
    }
  },
  retryDelay: (retryCount) => {
    return retryCount *1000;
  }
});

const fetchTokenWithRetry = async(getAccessTokenSilently: () => any,retries = 3, delay = 300) => {
  let attempt = 0;

  while (attempt < retries) {
      try {
         const token = await getAccessTokenSilently();
  
          return token;

      } catch (error) {
          if (attempt === retries - 1) {
              throw new Error('All attempts to fetch token failed.');
          }
          await new Promise((resolve) => setTimeout(resolve, delay));
          attempt++;
      }
  }
}

//Binder function to bind intial getAccesstokenSilenty() Instance
export const interceptorTokenBinder = (getAccessTokenSilently: any) => {
  // Request interceptor for API calls
  instance.interceptors.request.use(
    async (config) => {
      try {
        if (accessToken === '') {
          accessToken = await fetchTokenWithRetry(getAccessTokenSilently,2,500)
          config.headers.Authorization = `Bearer ${accessToken}`
        } else {
          config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
      } catch (error) {
        return config
      }
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Response interceptor for API calls
  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      console.log({error});
      const maxRetry =1;
      
      
      const originalRequest = error.config;
    

      if (!originalRequest.__retryCount) {
        originalRequest.__retryCount = 0; // Initialize the retry count
      }
      if (error?.response==undefined&& originalRequest.__retryCount <=1) {
        originalRequest.__retryCount += 1;
        await new Promise((resolve)=>setTimeout(resolve,1000));
        return instance(originalRequest)
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        accessToken = await fetchTokenWithRetry(getAccessTokenSilently,2,500)
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        return instance(originalRequest)
      }
      
      return Promise.reject(error)
    }
  )
}

export const baseAxios = axios.create({
  baseURL: `${apiUrl}`,
})

export default instance
