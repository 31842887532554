import {useEffect} from 'react'
import {Navigate, Routes,Route, useLocation, useNavigate} from 'react-router-dom'
import {useAuth0} from '@auth0/auth0-react'
export function Logout() {
  const {logout} = useAuth0();
  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    const { search, } = location
    const searchParams = new URLSearchParams(search);
        const loginError = searchParams.get("loginError");

        if(loginError){
          logout();
          // logout({returnTo: loginError?'https://auth.stage.localhost:3000/auth/login/?loginError="access denied"':""});
          navigate('/auth/login/?loginError=Wrong email or password.')
        }else{
          logout();
        }
    
  }, [logout])

  return (
    <></>
  )
}
