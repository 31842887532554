import { Suspense, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { I18nProvider } from './plugins/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from './plugins/layout/core'
import { MasterInit } from './plugins/layout/MasterInit'
import { Provider } from 'react-redux'
import store from './stores/store'
import 'bootstrap/dist/js/bootstrap.min.js'
import { Tooltip } from 'bootstrap'
import { useAuth0 } from '@auth0/auth0-react'
const App = () => {
  const TooltipFunction = () => {
    let tooltip = document.querySelectorAll('[data-bs-toggle="tooltip"]')

    tooltip.forEach((tooltipElement) => {
      new Tooltip(tooltipElement)
    })
  }
  if (process.env.NODE_ENV != "development") {
    console.log = function () { }
  }
  
  document.addEventListener("hide.bs.modal", (e) => {
    localStorage.setItem("isModelOpen","false")
  })

  document.addEventListener("show.bs.modal", (e) => {
    //Remove all backdrops when triggered
   setTimeout(()=>{
    const get = document.getElementsByClassName("modal-backdrop fade show");
    localStorage.setItem("isModelOpen","true")
    if(get.length>1){
      for(let i=0;i<get.length;i++){
        get[i].remove()
      }
    }
   },300)
  });

  Notification.requestPermission().then(function(permission) {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.log('Unable to get permission to notify.');
    }
  });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Provider store={store}>
        <I18nProvider>
          <LayoutProvider>
            <Outlet />
            <MasterInit />
          </LayoutProvider>
        </I18nProvider>
      </Provider>
    </Suspense>
  )
}

export { App }
