//import axios from "axios";
import { UserDetailAction } from '../actiontypes/account'
import axios from '../../../config/axiosConfig/axiosinterceptor'

export const success = (payload) => {
  return {
    type: UserDetailAction.success,
    payload: payload,
  }
}

export const loading = () => {
  return {
    type: UserDetailAction.loading,
  }
}

export const errors = (errors) => {
  return {
    type: UserDetailAction.error,
    payload: errors,
  }
}

export const fetchUserDetails = (email) => (dispatch) => {
  dispatch(loading())
  // const ImpersonateMailSession = sessionStorage.getItem('ImpersonateMail')

  axios
    .post(`/customer/userdetails`, { email: email })
    .then(({ data }) => {
      dispatch(success(data ?? null))
    })
    .catch((err) => {
      dispatch(errors(err.response.data))
    })
}
