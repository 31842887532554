import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";
import { toAbsoluteUrl } from "../helpers";
import { Navigate } from "react-router";
import DomainUrl from "../../utilies/custom/functions/DomainUrl";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const TenantList: FC = () => {

    const { user, isAuthenticated } = useAuth0()
    console.log({ user });
    const urlParams = new URLSearchParams(window.location.search);
    const isErrorExist = urlParams.get('error');
    const isErrorDescriptionExist = urlParams.get('error_description');
    if (isErrorDescriptionExist && isErrorExist){
        window.location.href= `https://auth.${process.env.REACT_APP_BASE_DOMAIN}/unauthorized`
    }

        if (
            window.location.hostname.split('.')[0].toLowerCase() == "auth" &&
            ["superadmin", "admin"].includes(user?.role)
        ) {
            window.location.href = `https://app.${process.env.REACT_APP_BASE_DOMAIN}/login`
            return <></>
        }
    if(user?.tenants.length==1 && user.activeStatus[user.tenants[0]]){
        window.location.href = `${DomainUrl(user?.tenants[0])}/login`;
        return  <></>
    }
    return (
        <>
            {
                isAuthenticated && (
                    <div className='w-100 h-100 bg-primary'>
                        <div className='d-flex flex-column flex-center '>
                            <div className='d-flex flex-column flex-center flex-column-fluid'>
                                <div className='d-flex flex-column flex-center text-center p-10 '>
                                    <div className='card card-flush w-lg-500px py-5'>
                                        <div className='card-body py-15 py-lg-20'>
                                            <img
                                                alt='Logo'
                                                src={toAbsoluteUrl('/media/logos/PurifAI_Logo-2.png')}
                                                className=' h-50px'
                                            />
                                            <h3 className='fw-bolder text-gray-900 mb-5 mt-5'>
                                                {user?.tenants.length === 1 ? "You have only access to this account" : "You have only access to these accounts"}
                                            </h3>
                                            <div className='fs-6 mb-8 '>
                                                <span className='fw-semibold text-gray-900 d-flex flex-column justify-content-center'>
                                                    {user && user?.tenants.map((d: string) => {
                                                        {/* {["test","test1"].map((d: string) => { */ }
                                                        return (
                                                            <div className="d-grid gap-2 col-6 mx-auto p-3 ">{
                                                                !user.activeStatus[d] ? (
                                                                    <>
                                                                        <OverlayTrigger overlay={<Tooltip placement="right-end">No Access to the Account</Tooltip>}>
                                                                            <Button disabled={!user?.activeStatus[d]} style={{ pointerEvents: 'all' }} className="btn btn-primary rounded-pill" onClick={() => window.location.href = `${DomainUrl(d)}/login`}>
                                                                                {d}
                                                                            </Button>

                                                                        </OverlayTrigger>
                                                                    </>
                                                                ) : (
                                                                    <button disabled={!user?.activeStatus[d]} className="btn btn-primary rounded-pill" onClick={() => window.location.href = `${DomainUrl(d)}/login`}>{d}</button>
                                                                )
                                                            }
                                                            </div>

                                                        )
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export { TenantList }