/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'

import {Link, useLocation, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../plugins/helpers'
import {useAuth0} from '@auth0/auth0-react'
// import {getUserByToken, login} from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
// import {useAuth} from '../core/Auth'

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/
interface propState {
  data: {email: string}
}

export default function IdelLogoutPage() {
  const {logout} = useAuth0()
  const location = useLocation()
  const navigate = useNavigate()
  
  return (
    <div
      className='w-100 h-100 bg-primary'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg5.jpg')})`,
      // }}
    >
      <div className='d-flex flex-column flex-center '>
        <div className='d-flex flex-column flex-center flex-column-fluid'>
          <div className='d-flex flex-column flex-center text-center p-10 '>
            <div className='card card-flush w-lg-650px py-5'>
              <div className='card-body py-15 py-lg-20'>
                <div className='mb-14'>
                  <a href='/'>
                    <img
                      alt='Logo'
                      src={toAbsoluteUrl('/media/logos/PurifAI_Logo-2.png')}
                      className='w-200px '
                    />
                  </a>
                </div>

                <h1 className='fw-bolder text-gray-900 mb-5'>Your session has expired. Please log In again.</h1>

                <div className='fs-6 mb-8'>
                  <span className='fw-semibold text-gray-900'>
                    {/* Didn’t receive an email {mail ? `to` : '? '} */}
                  </span>
                  <br />
                  {/* <span className='fw-semibold text-gray-900'>{mail && ` ${mail} ? `} </span> */}
                  {/* <Link to='/auth/resend-mail' className='link-primary fw-bold'>
                    Try Again
                  </Link> */}
                </div>
                

                <div className='d-flex flex-center mb-10'>
                  <button
                    className='m-3 w-125px btn btn-sm btn-primary'
                    onClick={() => {
                      localStorage.clear()
                      // navigate('/auth/login')
                      window.location.href=`https://auth.${process.env.REACT_APP_BASE_DOMAIN}/auth/login`
                    }}
                  >
                    Log In
                  </button>
                 
                </div>

                <div className='mb-0'>
                  <img
                    src={toAbsoluteUrl('/media/logos/session_timeout_warning.jpg')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
