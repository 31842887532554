import {useState} from 'react'
import {ToolTipAction} from '../actiontypes/tooltip'

const initialState = {
  toolTipData: null,
  isToolTipLoading: false,
  isToolTipSuccess: false,
  isToolTipError: false,
}

const ToolTipDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ToolTipAction.toolTipSuccess:
      return {
        ...state,
        toolTipData: action.payload,
        isToolTipSuccess: true,
        isToolTipLoading: false,
        isToolTipError: false,
      }
      break
    case ToolTipAction.toolTipLoading:
      return {...state, isToolTipSuccess: false, isToolTipLoading: true, isToolTipError: false}
      break
    case ToolTipAction.toolTipError:
      return {
        ...state,
        toolTipData: action.payload,
        isToolTipSuccess: false,
        isToolTipLoading: false,
        isToolTipError: true,
      }
      break
    default:
      return {...state}
      break
  }
}

export default ToolTipDetailsReducer
