// const appRoles = {
//     owner: "owner",
//     admin: "admin",
//     user: "user"
// }

const appRoles = {
    accountAdmin: "account admin",
    organizationAdmin: "organization admin",
    siteOperator: "site operator",   
    systemSuperAdmin: "systemsuperadmin",
    systemAdmin: "systemadmin",
}

const accountRoles = {
    customer: "customer",
    freeuser: "freeuser",
    admin: "admin",
    superAdmin: "superadmin"
}

// const roles = {
//     app: {
//         owner: [appRoles.owner],
//         admin: [appRoles.admin],
//         user: [appRoles.user],
//         ownerAndAdmin: [appRoles.owner, appRoles.admin]
//     },
//     account: {
//         customer: [accountRoles.customer],
//         freeuser: [accountRoles.freeuser],
//         customerAndFreeuser: [accountRoles.customer,accountRoles.freeuser],
//         admin: [accountRoles.admin],
//         accountRoles: [accountRoles.superAdmin]
//     }
// }
const roles = {
    app: {
        accountAdmin: [appRoles.accountAdmin],
        organizationAdmin:[appRoles.organizationAdmin],
        siteOperator: [appRoles.siteOperator],
        accountAndOrganizationAdmin: [appRoles.accountAdmin, appRoles.organizationAdmin],
        organizationAdminAndSiteOperator: [appRoles.siteOperator, appRoles.organizationAdmin],
        all: [appRoles.accountAdmin, appRoles.organizationAdmin, appRoles.siteOperator],
        systemSuperAdmin: [appRoles.systemSuperAdmin],
        systemAdmin: [appRoles.systemAdmin],
        allSystemRole:  [appRoles.systemSuperAdmin,appRoles.systemAdmin]
    },
    account: {
        customer: [accountRoles.customer],
        freeuser: [accountRoles.freeuser],
        customerAndFreeuser: [accountRoles.customer,accountRoles.freeuser],
        admin: [accountRoles.admin],
        accountRoles: [accountRoles.superAdmin],
        superAdmin: [accountRoles.superAdmin],
        superAdminAndAdmin: [accountRoles.superAdmin, accountRoles.admin],
        all: [accountRoles.superAdmin, accountRoles.customer, accountRoles.freeuser]
    }
}

module.exports = roles