import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom';
import { FallbackView } from '../../../plugins/partials';

export function Auth0Login() {
  const { loginWithRedirect,isAuthenticated } = useAuth0();
  const [currentPath, setCurrentPath] = useState<any>(null);
  const [loginError,setLoginError] = useState('');
  const location = useLocation();

  useEffect(() => {
    
    document.title = 'Login | PurifAI Console'
    const { search, } = location
      const searchParams = new URLSearchParams(search);
      const paramEmail = searchParams.get("email");
      const loginErrorData = searchParams.get('loginError');
      loginErrorData? setLoginError(loginErrorData): setLoginError("");
      if (paramEmail) {
        console.log({paramEmail})
        localStorage.setItem("ImpersonateSession", JSON.stringify({ mail: paramEmail, isImpersonate: true }))
      }
    const locations = window.location.hostname;
    setCurrentPath(window.location.origin);
    const isAuthDomain = locations && locations.split('.')[0] === 'auth';
    if (isAuthDomain) {
      setCurrentPath(`https://${locations.split('.')[0]}.${process.env.REACT_APP_NODE_ENV === "staging" ? 'stage.' : ''}${process.env.REACT_APP_DOMAIN}/tenants`)
    }
   
  }, [])
  loginWithRedirect({connection: process.env.REACT_APP_AUTH0_DB_NAME, ...(currentPath && { redirectUri: currentPath,loginError })})   
  return <FallbackView></FallbackView>
}
