import { Location, matchRoutes, useLocation } from "react-router-dom";

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

// export function checkIsActive(pathname: string, url: string, location?:Location) {
   
//   const current = getCurrentUrl(pathname)
//   if (!current || !url) {
//     return false
//   }

//   if (current === url) {
//     return true
//   }
//   if (location) {
//     const someRoutes = [
//       { path: url }
//     ];
//     const matches = matchRoutes(someRoutes, location);
//     if (matches && matches?.length > 0) {
//       return true
//     }
//   }
//   return false
// }

export function checkIsActive(url: string, location?:Location) {
  if (location) {
    const someRoutes = [
      { path: url }
    ];
    const matches = matchRoutes(someRoutes, location); 
    if (matches && matches?.length > 0) {
      return true
    }
  }
  return false
}
