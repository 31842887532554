/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../stores/store'
import { IOwner, IUserData } from '../../../../stores/reducerInterface/account'
import DomainUrl from '../../../../utilies/custom/functions/DomainUrl'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import clsx from 'clsx'
import { helpDetails } from '../../../../stores/action/help'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
const toolbarButtonMarginClass = 'ms-2 ms-lg-6',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'
const UserHeaderMenu: FC = () => {
  //const user = JSON.parse(auth as string)
  const { user, logout } = useAuth0()
  const userdetails = useSelector<RootState, IUserData>((state) => state.userdetails)
  const data = userdetails?.data?.data as IOwner;
  const impersonate = useSelector<RootState, any>((state) => state.impersonatesDetails)
  const { impersonateData } = impersonate;
  const { isSuccess } = userdetails;
  const dispatch = useDispatch()
  //const {logout, user} =useAuth0()
  const UserInfo = () => {
    if (isSuccess) {
      return (
        <>
          <div className='text-capitalize'>
            {data?.userDetails?.firstName} {data?.userDetails?.lastName}
          </div>
        </>
      )
    }
    return <></>
  }
  return (
    <Dropdown align="end">
      <Dropdown.Toggle as="div" className=" cursor-pointer symbol p-3 no-after" variant="link"
        style={{ border: 'none', boxShadow: 'none', backgroundColor: 'transparent' }}
      >
        {data?.userDetails && (
          <OverlayTrigger placement="bottom" overlay={<Tooltip>{data?.userDetails?.firstName + ' ' + data?.userDetails?.lastName}</Tooltip>}>
            <div className="symbol symbol-40px">
              <span className="symbol-label bg-primary text-inverse-primary text-uppercase fw-bolder">
                {data?.userDetails?.firstName.substring(0, 1)}
                {data?.userDetails?.lastName.substring(0, 1)}
              </span>
            </div>
          </OverlayTrigger>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-400px">
        <Dropdown.Item as="div" className="menu-item px-3 no-hover" onClick={(event) => { event.preventDefault(); event.stopPropagation(); }} >
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              {isSuccess && (
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">
                  {data?.userDetails?.firstName + ' ' + data?.userDetails?.lastName}
                </Tooltip>}>
                  <div className="symbol symbol-45px">
                    <span className="symbol-label bg-primary text-inverse-primary text-uppercase fw-bolder">
                      {data?.userDetails?.firstName.substring(0, 1)}
                      {data?.userDetails?.lastName.substring(0, 1)}
                    </span>
                  </div>
                </OverlayTrigger>
              )}
            </div>

            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {isSuccess && <UserInfo />}
              </div>
              <div className="fw-bold text-muted fs-7 text-break">{user?.email}</div>
            </div>
          </div>
        </Dropdown.Item>

        <Dropdown.Divider style={{ color: "#9b9fb3" }} />

        {data?.accountDetails?.stripeStatus !== 'canceled' && (
          <Dropdown.Item as={Link} to="/profile" className="menu-link px-5 py-3 fw-bold custom-dropdown-item">
            <KTSVG  path={toAbsoluteUrl('/media/icons/duotune/communication/com006.svg')} className={clsx('svg-icon svg-icon-2 px-1')} /> My Profile
          </Dropdown.Item>
        )}

        {data?.accountDetails?.role && ["freeuser", "customer"].includes(data?.accountDetails?.role) && (
          <Dropdown.Item className='menu-link px-5 py-3 fw-bold custom-dropdown-item'
            data-bs-toggle='modal'
            data-bs-target={data?.accountDetails?.role === "freeuser" ? '#kt_modal_freeuser_steper' : '#kt_modal_steper'}
            onClick={() => dispatch<any>(helpDetails(1))}
          >
              <KTSVG  path={toAbsoluteUrl('/media/icons/duotune/general/gen046.svg')} className={clsx('svg-icon svg-icon-2 px-1')} /> Help
          </Dropdown.Item>
        )
        }
        {!impersonateData?.isImpersonate && (
          <Dropdown.Item
            as="a"
            className="menu-link px-6 py-3 fw-bold custom-dropdown-item"
            onClick={() => {
              localStorage.clear();
              logout({
                client_id: process.env.REACT_APP_CLIENTID,
                returnTo: `${DomainUrl()}`,
              });
              document.cookie = 'my_cookie=; path=/; domain=.test1.stage.verifyalarms.local; expires=' + new Date(0).toUTCString();
            }}
          >
            <KTSVG  path={toAbsoluteUrl('/media/icons/duotune/arrows/arr002.svg')} className={clsx('svg-icon svg-icon-2 px-1')} />Sign Out
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { UserHeaderMenu }
