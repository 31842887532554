import {FormsDataAction} from "../actiontypes/FormErrorIndicator"

const initialFormsData = {
    autoVerify: {},
    userVerify:{},
    general:{},
    cameraIdentifier:{},
    outgoingNotification:{},
    systemSetting:{},
    incomingNotification:{},
    geolocation:{}
}

const initialState = {
    FormData:{...initialFormsData},
    isFormDataLoading:false,
    isFormDataSuccess:false,
    isFormDataError:false
}

const FormsDataReducer = (state=initialState, action) => {
    switch (action.type) {
        case FormsDataAction.isFormDatasuccess:
            return {...state, 
                FormData: {...state.FormData,...action.payload}, 
                isFormDataSuccess: true,
                isFormDataLoading: false,
                isError: false
            };
            break;
        case FormsDataAction.isFormDataReset:
            return {...state, 
                FormData: {...initialFormsData}, 
                isFormDataSuccess: true,
                isFormDataLoading: false,
                isError: false
            };
            break;      
        case FormsDataAction.isFormDataloading:
            return {...state,
                isFormDataSuccess: false,
                isFormDataLoading: true,
                isError: false
            }
            break;
        case FormsDataAction.isFormDataerror:
            return {...state,
                FormData: action.payload,
                isFormDataSuccess: false,
                isFormDataLoading: false,
                isError: true
            }
            break;
        default:
            return {...state}
            break;
    }
}

export default FormsDataReducer


