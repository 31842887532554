import {ImpersonateAction} from '../actiontypes/impersonate'

const initialState = {
  impersonateData: null,
  isImpersonateDataLoading: false,
  isImpersonateSuccess: false,
  isImpersonateError: false,
}

const ImpersonateDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ImpersonateAction.impersonateSuccess:
      return {
        ...state,
        impersonateData: action.payload,
        isImpersonateSuccess: true,
        isImpersonateDataLoading: false,
        isImpersonateError: false,
      }
      break
    case ImpersonateAction.impersonateLoading:
      return {
        ...state,
        isImpersonateSuccess: false,
        isImpersonateLoading: true,
        isImpersonateError: false,
      }
      break
    case ImpersonateAction.impersonateError:
      return {
        ...state,
        ImpersonateData: action.payload,
        isImpersonateSuccess: false,
        isImpersonateLoading: false,
        isImpersonateError: true,
      }
      break
    default:
      return {...state}
      break
  }
}

export default ImpersonateDetailsReducer
