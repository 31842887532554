import { FC } from "react";
import { toAbsoluteUrl } from "../plugins/helpers";
import { useNavigate, useParams } from "react-router-dom";

const InquiryInfo: FC = () => {
    const navigate = useNavigate();
    const params = useParams()
    return (
        <>
            <div
                className='w-100 h-100 bg-primary'
            >
                <div className='d-flex flex-column flex-center '>
                    <div className='d-flex flex-column flex-center flex-column-fluid'>
                        <div className='d-flex flex-column flex-center text-center p-10 '>
                            <div className='card card-flush w-lg-650px py-5'>
                                <div className='card-body py-15 py-lg-20'>
                                    <div className='mb-14'>
                                        <a href='/'>
                                            <img
                                                alt='Logo'

                                                src={toAbsoluteUrl('/media/logos/PurifAI_Logo-2.png')}
                                                className='w-200px '
                                            />
                                        </a>
                                    </div>
                                    <h1 className='fw-bolder text-gray-900 mb-5'></h1>
                                    <div className='fs-6 mb-2'>
                                        <span className='fw-semibold text-gray-900'>
                                        Your inquiry for <strong>"{params?.planname}"</strong> has been received,
                                        </span>
                                    </div>
                                    <div className='fs-6 mb-8'>
                                    <span className='fw-semibold text-gray-900'>
                                        Our team will get back to you shortly.
                                        </span>
                                    </div>
                                    <div className='d-flex flex-center mb-11'>
                                        <button
                                            className='m-3 w-125px btn btn-sm btn-primary'
                                            onClick={() => {
                                                navigate('/')
                                            }}
                                        >
                                            Home
                                        </button>
                                    </div>
                                    <div className='mb-0'>
                                        <img
                                            src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/17.png')}
                                            className='mw-100 mh-300px theme-light-show'
                                            alt=''
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { InquiryInfo }