
interface Props {
  title?: string,
  options?: {
    body?: string,
    icon?: string
  }
}

const WebPushNotification = ({
  title="",
  options
}: Props) => {
    if (!("Notification" in window)) {
      console.error("This browser does not support notifications.");
      return;
    }
    if (Notification.permission === "granted") {
      const notificationIns = new Notification(title, options);
      //Open the PurifAI Client tab in the browser when notification is clicked
      notificationIns.onclick = function () { 
        window.focus();
      }
    } else if (Notification.permission !== "denied") {
      window.Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          //Open the PurifAI Client tab in the browser when notification is clicked
          const notificationIns = new Notification(title, options);
          notificationIns.onclick = function () { 
            window.focus();
          }
        }
      }).catch(err => { 
        console.log(`${err.message}`)
      });
    }
}

export default WebPushNotification;

