import ReactDOM from 'react-dom'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
//Bootstrap bundle file
import 'bootstrap/dist/js/bootstrap.bundle'
import {BrowserRouter} from 'react-router-dom'
// Apps
import {MetronicI18nProvider} from './app/plugins/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './app/styles/sass/style.scss'
import {AppRoutes} from './app/routing/AppRoutes'

import Auth0ProviderWithHistory from '../src/Auth0Provider'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

Chart.register(...registerables)

const queryClient = new QueryClient()
//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY as string)
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          {/* //<AxiosInterceptor> */}
          <AppRoutes />
          {/* </AxiosInterceptor> */}
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </MetronicI18nProvider>
  </QueryClientProvider>,
  document.getElementById('root') as HTMLElement
)
