import {SiteAction} from "../actiontypes/siteAction"

const initialState = {
    siteData: null,
    currentSite: null,
    isSiteSuccess:false,
    isSiteLoading:false,
    isSiteError:false
}

const SiteReducer = (state=initialState, action) => {
    switch (action.type) {
        case SiteAction.isSiteSuccess:
            return {...state, 
                siteData: action.payload,
                currentSite: null,
                isSiteSuccess: true,
                isSiteLoading: false,
                isSiteError: false
            };
        case SiteAction.isSiteLoading:
            return {
                ...state,
                currentSite: null,
                isSiteSuccess: false,
                isSiteLoading: true,
                isSiteError: false
        }
        case SiteAction.isSiteError:
            return {...state,
                siteData: action.payload,
                currentSite: null,
                isSiteSuccess:false,
                isSiteLoading:false,
                isSiteError:true
            }
        case SiteAction.LoadCurrentSite:
            return {...state,
                currentSite: action.payload,
                isSiteSuccess:false,
                isSiteLoading:false,
                isSiteError:true
        }
        default:
            return {...state}
    }
}

export default SiteReducer


