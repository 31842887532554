import { FC} from 'react'

export const TrimSideTextRaw = (data:string) => {
  try{
    let inputValue=""
    if(data?.endsWith(" ") && data?.length > 1){
      inputValue = data?.trim().replace(/\s\s+/g, ' ')+" ";
    }
    else{
      inputValue = data?.trim().replace(/\s\s+/g, ' ');
    }
    return inputValue
  }
  catch(err){
    return data
  }
}

export const TrimAllSpaceTextRaw = (data:string) => {
  try{
    let inputValue=""
    if(data?.length > 0){
      inputValue = data.replace(/\s/g, '');
    }else{
      inputValue = data
    }
    return inputValue
  }
  catch(err){
    return data
  }
}
