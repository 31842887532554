import { useAuth0 } from "@auth0/auth0-react"
import { FC, useEffect } from "react"
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "../stores/store";

export const TenantAuth: FC = () => {

    const { user, isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();

    const impersonate = useSelector<RootState, any>((state) => state.impersonatesDetails)
    const { impersonateData } = impersonate;

    useEffect(() => {
        const { search, } = location
        const searchParams = new URLSearchParams(search);
        const paramEmail = searchParams.get("email");
        if (paramEmail) {
            console.log({ paramEmail })
            let subdomain = searchParams.get("account");
            localStorage.setItem("ImpersonateSession", JSON.stringify({ mail: paramEmail, isImpersonate: true, account:subdomain }))
        }

        // const isRedirect = searchParams.get("redirect");
        // if (isRedirect) {
        //     navigate('/customers')
        // }
        if (isAuthenticated) {
            if (user) {
                if (window.location.hostname.split('.')[0].toLowerCase() == "auth") {
                    if (
                        window.location.hostname.split('.')[0].toLowerCase() == "auth" &&
                        ["superadmin", "admin"].includes(user?.role)
                    ) {
                        window.location.href = `https://app.${process.env.REACT_APP_BASE_DOMAIN}/login`
                        return
                    }
                    return navigate('/tenants')
                }
                let tempData = localStorage.getItem("ImpersonateSession");
                let ImpersonationData = tempData? JSON.parse(tempData): {};

                if (["superadmin", "admin"].includes(user?.role) && window.location.hostname.split('.')[0].toLowerCase() != 'app' && !ImpersonationData?.isImpersonate) {
                    window.location.href = `https://app.${process.env.REACT_APP_BASE_DOMAIN}/login`
                    return
                }

                if (["superadmin", "admin"].includes(user?.role) && window.location.hostname.split('.')[0].toLowerCase() != 'app') return navigate('/organizations')
                if (!["auth", 'app'].includes(window.location.hostname.split('.')[0].toLowerCase())) {
                    console.log("Working 2", window.location.hostname.split('.')[0].toLowerCase(), user?.activeStatus)
                    if (user?.activeStatus && !user?.activeStatus[window.location.hostname.split('.')[0].toLowerCase()]) return navigate('/no-access')
                }
                if (window.location.hostname.split('.')[0].toLowerCase() !== 'app' && user?.tenants.includes(window.location.hostname.split('.')[0].toLowerCase())) {
                    return navigate('/organizations')
                }
                if (window.location.hostname.split('.')[0].toLowerCase() == 'app') return navigate('/admin/dashboard')
            } else {
                navigate('/no-access')
            }
        } else {
            navigate('/login')
        }

    }, [isAuthenticated, location]);


    return (<></>)
}