/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../helpers'
//import axios from 'axios'
import axios from '../../../../../config/axiosConfig/axiosinterceptor'
import { useAuth0 } from '@auth0/auth0-react'
import swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../stores/store'
import { fetchUserDetails } from '../../../../stores/action/account'
import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment-timezone'
import { IOwner, IUser, IUserData } from '../../../../stores/reducerInterface/account'
//const auth = localStorage.getItem('puretech-auth')

const Initialplans = [
  {
    title: '',
    subTitle: '',
    description: '',
    priceMonth: '',

    priceIdMonth: '',

    label: '',
    default: false,
    custom: false,
    features: [
      {
        title: '',
        supported: true,
      },
    ],
  },
]
interface plansType {
  title: string
  subTitle: string
  description: string
  priceMonth: string
  priceAnnual: string
  priceIdMonth: string
  priceIdAnnual: string
  label: string
  default: boolean
  custom: boolean
  features: [
    {
      title: string
      supported: boolean
    }
  ]
  planType: string
}

const UpgradePlan: FC = () => {
  const { getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
  const dispatch = useDispatch()
  const userdetails = useSelector<RootState, IUserData>((state) => state.userdetails)
  const data = userdetails?.data?.data as IOwner;
  const {isSuccess, isLoading, isError } =userdetails
  const [selected, setSelected] = useState('Silver')
  const [loading, setLoading] = useState(false)
  const [planid, setPlanids] = useState({
    newplan: {
      title: '',
      amount: 0,
    },
    currentplan: {
      title: '',
      amount: 0,
    },
    period: {
      end: 0,

      start: 0,
    },
    total_amount: {
      title: '',
      amount: 0,
    },
  })

  const [plans, setPlans] = useState(Initialplans)
  const [currentPlan, setCurrentPlan] = useState({ plan: '', period: '', status: '' })
  useEffect(() => {
    setPrice(false)
  }, [''])

  useEffect(() => {
    setLoading(isLoading)
    if (isSuccess) {
        const { interval, status, priceid } = data?.subscriptionDetails?.currentplan
        // setSelected(priceid)
        setCurrentPlan({ plan: priceid, period: interval, status: status })
      }
  }, [data, isSuccess, isLoading, isError])

  
  // set plan details from  database
  const setPrice = async (updateUserDetails: boolean) => {
    let DBPlans: plansType[]
    let urls = `/subscription/trialplans?planType=paid`
    await axios
      .get(urls)
      .then((res: any) => {
        if (res.data.result) {
          const getPaidPlans = res.data.data.plans.filter((d: any) => d.planType === 'paid')
          setPlans(getPaidPlans)
          setSelected(getPaidPlans[0]?.priceIdMonth)
          DBPlans = getPaidPlans as plansType[]
        }
      })
      .catch((error: AxiosError) => { })
    if (updateUserDetails) {
      const email = data?.userDetails?.email

      dispatch<any>(fetchUserDetails(email))
    }
  }
  // upgrade plan for user
  const HandleUpgradePrice = async () => {
    setLoading(true)
    let url = `/subscription/upgrade`

    let priceId = plans.find((el) => el.priceIdMonth === selected)?.priceIdMonth

    await axios
      .post(url, {
        email: data?.userDetails?.email,
        planId: priceId
      })
      .then(async (res) => {
        if (res.data.result) {
          document.getElementById('cancel_upgrade')?.click()
          const { stripeStatus } = res.data.data

          if (stripeStatus === 'active') {
            dispatch<any>(fetchUserDetails(data?.userDetails?.email))
            // await getAccessTokenSilently({ ignoreCache: true });
            // await getIdTokenClaims();
            setPrice(true)
            if (stripeStatus === 'active' && user?.["subscriptionStatus"] === "canceled") {
              swal.fire({
                heightAuto:false,
                title: '',
                text: "Subscription is activated!",
                icon: "success",
              })
              setTimeout(() => window.location.reload(), 2000);

            } else {
              swal.fire({
                heightAuto:false,
                title: '',
                text: 'Subscription is activated!',
                icon: 'success',
              })
            }


          } else {
            swal.fire({
                heightAuto:false,
              title: '',
              text: 'Subscription is not activated!', //res.error as string,
              icon: 'error',
            })
          }
        }
      })
      .catch((error: any) => {
        swal.fire({
                heightAuto:false,
          title: '',
          text:
            error?.response?.data?.message ||
            error?.response?.data ||
            'Subscription is not activated, try again later!', //res.error as string,
          icon: 'error',
        })
      }).finally(() => {
        setLoading(false)
      })

  }
  // fetch plan details with selected plan
  // const HandleOnchange = async (planId: string) => {
  //   setSelected(planId)
  //   setLoading(true)
  //   let url = `/subscription/upcominginvoice/preview`

  //   await axios
  //     .post(url, {
  //       email: data.data.userDetails.email,
  //       planId: planId,
  //     })
  //     .then((res: any) => {
  //       let data = res.data
  //       if (data.result) {
  //         setPlanids(data.data)
  //         let newProtation = data.data
  //         setPlanids({
  //           ...planid,
  //           ...newProtation,
  //         })
  //       }
  //     })
  //     .catch((error) => {
  //      console.error(error)
  //     }).finally(()=>{
  //       setLoading(false)
  //     })
  // }


  return (
    <div className='modal fade' id='kt_modal_upgrade_plan' aria-hidden='true'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body pt-0 pb-15 px-5 px-xl-20'>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>Upgrade My Plan</h1>

              {/* <div className='text-muted fw-bold fs-5'>
                NOTICE: If the total alarms per month is projected to exceed your plan, you will be
                notified and given the opportunity to upgrade to a higher volume plan. If you choose
                not to upgrade, once the monthly limit is reached additional incoming alarms will be
                forwarded without automatic verification processing or retention.
              </div> */}
            </div>

            <div className='d-flex flex-column '>
              <div className='row mt-10'>
                <div className='col-lg-6 mb-10 mb-lg-0'>
                  <div className='nav flex-column'>
                    {plans?.map((plan, index) => {
                      
                      return (
                        <div
                          onClick={() => {
                            setSelected(plan.priceIdMonth)
                            //if (plan.priceIdMonth !== currentPlan.plan) {
                            // HandleOnchange(plan.priceIdMonth)
                            // }
                          }}
                          className={
                            `nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 mb-6 ` +
                            (plan.priceIdMonth === selected && 'active ') +
                            (!plan.custom && 'btn-active btn-active-primary ')
                          }
                          data-bs-toggle='tab'
                          data-bs-target={`#kt_upgrade_plan_${plan.title}`}
                          key={index}
                        >
                          <div className='d-flex align-items-center me-2'>
                            <div className='form-check form-check-success me-6'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='plan'
                                value={plan.priceIdMonth}
                                checked={selected === plan.priceIdMonth}
                                onChange={(e) => {
                                  setSelected(e.target.value)
                                }}
                              />
                            </div>

                            <div className='flex-grow-1'>
                              <div className='d-flex align-items-center fs-2 fw-bolder flex-wrap'>
                                {plan.title}

                                {plan.priceIdMonth === currentPlan.plan && (
                                  <span className='badge badge-secondary ms-2 fs-7'>
                                    Current plan
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className='ms-5'>
                            {plan.custom && (
                              <a
                                className='btn btn-sm btn-primary'
                                href='https://www.puretechsystems.com/support'
                                target='_blank'
                              >
                                Contact Us
                              </a>
                            )}
                            {!plan.custom && (
                              <>
                                <span className='mb-2'>$</span>

                                <span className='fs-3x fw-bolder'>{plan.priceMonth}</span>

                                <span className='fs-7 opacity-50'>
                                  /<span data-kt-element='period'>{'Month'}</span>
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='tab-content rounded h-100 bg-light p-10'>
                    {plans?.map((plan, index) => {
                      return (
                        <div key={`custom${index}`}>
                          {!plan.custom && (
                            <>
                              {selected === plan.priceIdMonth && (
                                <div
                                  className={
                                    `tab-pane fade ` +
                                    (selected === plan.priceIdMonth && ' active show')
                                  }
                                  id={`kt_upgrade_plan_${plan.title}`}
                                  key={index}
                                >
                                  <div className='pb-5'>
                                    <h2 className='fw-bolder text-dark'>
                                      What’s in the {plan.title} Plan?
                                    </h2>
                                  </div>

                                  <div className='pt-1'>
                                    {plan.features!.map((feature, i) => {
                                      return (
                                        <div
                                          className={
                                            `d-flex align-items-center` +
                                            (i !== plan.features!.length - 1 && ' mb-7')
                                          }
                                          key={`${i}-${feature.title}`}
                                        >
                                          {feature.supported && (
                                            <>
                                              <span className='fw-bold fs-5 text-gray-900 flex-grow-1'>
                                                {feature.title}
                                              </span>

                                              <KTSVG
                                                path='/media/icons/duotune/general/gen043.svg'
                                                className='svg-icon-1 svg-icon-success'
                                              />
                                            </>
                                          )}
                                          {!feature.supported && (
                                            <>
                                              <span className='fw-bold fs-5 text-gray-900 flex-grow-1'>
                                                {feature.title}
                                              </span>
                                              <KTSVG
                                                path='/media/icons/duotune/general/gen040.svg'
                                                className='svg-icon-1'
                                              />
                                            </>
                                          )}
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button
                type='reset'
                id='cancel_upgrade'
                className='btn btn-danger me-3'
                data-bs-dismiss='modal'
              >
                Discard
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                disabled={(selected === currentPlan.plan && data?.accountDetails?.stripeStatus!== "canceled") || loading}
                onClick={() => {
                  swal
                    .fire({
                      title: 'Are you sure?',
                      text: 'Do you want to upgrade your plan?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, upgrade it!',
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        HandleUpgradePrice()
                      }
                    })
                }}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Loading...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { UpgradePlan }
