const API_PATH = {
  production: {
    API_ROOT: process.env.REACT_APP_SERVER_URL,
    API_LAMDA_ROOT: process.env.REACT_APP_LAMDA_URL
  },

  development: {
    API_ROOT: process.env.REACT_APP_SERVER_URL,
    API_LAMDA_ROOT: process.env.REACT_APP_LAMDA_URL
  },
}
const CLN_PATH = {
  production: {
    API_ROOT: process.env.REACT_APP_CLIENT_URL,
  },

  development: {
    API_ROOT: process.env.REACT_APP_CLIENT_URL,
  },
}

export {API_PATH, CLN_PATH}
