import {useState} from 'react'
import {SocketAction} from '../actiontypes/websocket';

const initialState = {
  socketData: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
}

const SocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SocketAction?.success:
      return {...state, socketData: action.payload, isSuccess: true, isLoading: false, isError: false}
      break
    case SocketAction?.loading:
      return {...state, isSuccess: false, isLoading: true, isError: false}
      break
    case SocketAction?.error:
      return {...state, socketData: action.payload, isSuccess: false, isLoading: false, isError: true}
      break
    default:
      return {...state}
      break
  }
}

export default SocketReducer;