import {useState} from 'react'
import {MessageDetailAction} from '../actiontypes/message'

const initialState = {
  messageData: null,
  isMessageLoading: false,
  isMessageSuccess: false,
  isMessageError: false,
}

const MessageDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MessageDetailAction.messageSuccess:
      return {
        ...state,
        messageData: action.payload,
        isMessageSuccess: true,
        isMessageLoading: false,
        isMessageError: false,
      }
      break
    case MessageDetailAction.messageLoading:
      return {...state, isMessageSuccess: false, isMessageLoading: true, isMessageError: false}
      break
    case MessageDetailAction.messageError:
      return {
        ...state,
        messageData: action.payload,
        isMessageSuccess: false,
        isMessageLoading: false,
        isMessageError: true,
      }
      break
    default:
      return {...state}
      break
  }
}

export default MessageDetailsReducer
