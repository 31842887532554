/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, lazy, useEffect } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { Logout, AuthPage } from '../modules/auth'
import { App } from '../App'
import { useAuth0 } from '@auth0/auth0-react'

import { interceptorTokenBinder } from '../../config/axiosConfig/axiosinterceptor'
import { interceptorTokenBinderWithLamda } from "../../config/axiosConfig/axiosInterceptorLamda"
import { Auth0IdleLogout } from '../utilies/auth0IdleTimeout'
import IdelLogoutPage from '../utilies/idleLogoutPage'
import React from 'react'
import { Auth0VerificationLink } from '../utilies/auth0Verification'
import { TenantList } from '../plugins/custom/TenantList'
import { TenantAuth } from '../utilies/TenantAuth'
import { NoAccessPage } from '../utilies/NoAccessPage'
import { Socket } from '../websocket/serverlessSocket'
// import CompleteAuthentication from '../modules/accounts/components/billing/cards/completePayment'
import { StripeWrapper } from '../services/StripeWrapper'
import { InquiryInfo } from '../utilies/InquirySubmitted'




/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env
type Plan_Data = {
  planType: string
  planID: string
}
const AppRoutes: FC = () => {

  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0()
  const LandingPage = lazy(() => import('../pages/LandingPage/LandingScreen'))
  const PrivateRoutes = lazy(() => import('./PrivateRoutes'))
  const ErrorsPage = lazy(() => import('../modules/errors/ErrorsPage'))
  const Unauthorized = lazy(() => import('../utilies/unauthorizedAccess'))
  const TermsCondition = lazy(() => import('../pages/terms-and-condition/TermsAndCondition'))
  const AccessDenied = lazy(() => import('../utilies/accessDenied'))
  const MailVerify = lazy(() => import('../utilies/email-verify'))
  const UserVerificationForm = lazy(() => import('../modules/auth/components/userVerificationForm'))
  const AccountVerificationForm = lazy(() => import('../modules/auth/components/AccountVerificationForm'))
  const UserInvite = lazy(() => import('../modules/auth/components/UserInvite'))

  //Binds the intance of getAccessTokenSilently to Axios
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      interceptorTokenBinder(getAccessTokenSilently)
      interceptorTokenBinderWithLamda(getAccessTokenSilently)
    }
  }, [getAccessTokenSilently, isAuthenticated, isLoading])
  const navigate = useNavigate();
  const SelectedPlan = (n: Plan_Data) => {
    navigate(`/auth/registration?plan=${n.planID}&planType=${n.planType}`)
  }
  useEffect(() => {

    if (window.location.hostname.split('.')[0].toLowerCase() == 'app') { localStorage.clear() }

  }, [window.location.origin, isAuthenticated, isLoading, user])


  return (
    <Routes>
      <Route element={<App />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='policy/*' element={<TermsCondition />} />
        <Route path='/logout/*' element={<Logout />} />
        <Route path='auth/userverification' element={<UserVerificationForm />} />
        <Route path='auth/accountverification' element={<AccountVerificationForm />} />
        <Route path='auth/user-invite' element={<UserInvite />} />
        <Route path='auth/*' element={<AuthPage />} />
        <Route path='/login' element={<AuthPage />} />
        <Route path="/no-access" element={<NoAccessPage />} />
        <Route path='tenant-auth' element={<TenantAuth />} />
        <Route path='tenants' element={<TenantList />} />
        <Route path='plans' element={<LandingPage />} />
        {
          !user?.role && window.location.hostname && window.location.hostname.split('.')[0].toLowerCase() == "auth" && (
            <>
              <Route path='plans' element={<LandingPage />} />
              <Route path="/tenants" element={<TenantList />} />
            </>
          )
        }
        {isLoading ? (
          <></>
        ) : isAuthenticated && user &&
          (user?.activeStatus[window.location.hostname.split('.')[0].toLowerCase()] &&
            window.location.hostname.split('.')[0].toLowerCase() != "auth") || (user?.role == "superadmin" || user?.role == "admin") ? (
          <>
            {user?.["subscriptionStatus"] === "canceled" ? 
            <Route index element={<Navigate to='/account/billing' />} />
              : (user?.['Role'].includes("customer") || user?.['Role'].includes("freeuser")) && user?.['isDefaultSetting'] == "true" ?
               <Route index element={<Navigate to='/technical/network/summary' />} />
                : user?.["isPaymentFailed"]?
                <Route index element={<Navigate to='/billing' />} />
                :
                <Route index element={<Navigate to='/tenant-auth' />} />
            }
            <Route path='/*' element={<Auth0IdleLogout><Socket><PrivateRoutes /></Socket></Auth0IdleLogout>} />

          </>
        ) : (
          <>
            <Route path='/session/logout' element={<IdelLogoutPage />} />
            <Route path='/mail' element={<MailVerify />} />
            <Route path='/unauthorized' element={<Unauthorized />} />
            <Route path='/access-denied' element={<AccessDenied />} />
            <Route path='auth/userverification' element={<UserVerificationForm />} />
            <Route path='auth/*' element={<AuthPage />} />
            <Route path='/inquiry/:planname' element={<InquiryInfo/>} />
            <Route path='verify' element={<Auth0VerificationLink />} />
            <Route path='/' element={<LandingPage />} />
            <Route path='*' element={<Navigate to={'/'} />} />
          </>
        )}
      </Route>

    </Routes>
  )
}

export { AppRoutes }
