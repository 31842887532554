import { useFormik } from "formik"
import { Link, useLocation, useNavigate } from "react-router-dom"
import * as Yup from 'yup'
import { TrimAllSpaceTextRaw } from "../utilies/conversion/TrimText"
import clsx from "clsx"
import React, { useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha";
import axios from "../../config/axiosConfig/axiosinterceptor"
import Swal from "sweetalert2"
import { KTSVG } from "../plugins/helpers"

const initialValues = {
    firstName: '',
    lastName: '',
    companyName: "",
    email: '',
    phoneNumber: "",
    estimatedCameraCount: 250,

}

const validateUSPhoneNumber = (value: any) => {
    const phNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    return phNumberRegex.test(value);
};

const InquiryForm = () => {
    const navigate = useNavigate()
    const recaptchaRef = React.createRef<any>();
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const { search } = useLocation();
    const params = new URLSearchParams(search)
    const planTypes = params.get('type');
    const plan = params.get('plan');
    const [maxCameraCount, setMaxCameraCount] = useState("");
    const [planName,setPlanName] =useState<string>("");


    const InquirySchema = Yup.object().shape({
        firstName: Yup.string()
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .trim()
            .matches(/^[A-Za-z]+$/g, 'First name allow only letters')
            .required()
            .label('First name'),
        lastName: Yup.string()
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .trim()
            .required()
            .matches(/^[A-Za-z]+$/g, 'Last name allow only letters')
            .label('Last name'),
        email: Yup.string()
            .email('Enter a valid Email ID')
            .min(3, 'Minimum 3 characters')
            .trim()
            .matches(/^(\S+$)/g, 'Email cannot allow blank spaces')
            .max(50, 'Maximum 50 characters')
            .required()
            .label('Email'),
        companyName: Yup.string()
            .min(3, 'Minimum 3 characters')
            .max(50, 'Maximum 50 characters')
            .trim()
            .matches(/^[A-Za-z ]+$/g, 'Company name allow only letters')
            .required()
            .label('Company name'),
        phoneNumber: Yup.string().label("Phone number").required()
            .test('Invalid phone number', value => validateUSPhoneNumber(value)),
        estimatedCameraCount: Yup.number().min(1).max(1000000).integer().required().label('Camera count'),
    })
    const formik = useFormik<any>({
        initialValues,
        validationSchema: InquirySchema,
        onSubmit: async (values) => {
            try {
                console.log({ values: values });
                const recaptchaValue = recaptchaRef.current.reset();
                const { firstName, lastName, email, phoneNumber, estimatedCameraCount, companyName } = values
                if (!token) return;
                setLoading(true);
                await axios.post("/inquiry-form/send", {
                    firstName, lastName, email, phoneNumber, estimatedCameraCount, companyName, token, planType: planTypes,planId: plan,planName
                }).then((res: any) => {
                    console.log({ res })
                    if (res?.data?.result) {
                        formik.resetForm();
                        // Swal.fire({
                        //     heightAuto:false,
                        //     icon: "success",
                        //     title: "Form submitted successfully!",
                        // })
                        setTimeout(()=>navigate(`/inquiry/${planTypes=="trial"? planName.concat(" ","with free trial") : planName}`),100);
                    }
                }).catch((err) => {
                    Swal.fire({
                        heightAuto:false,
                        icon: "error",
                        title: "Something went wrong, Try again!"
                    })
                }).finally(() => {
                    setLoading(false)
                    setToken(null)
                })


            } catch (error) {
                console.log({ error })
            }
        },

    })
    const checkPlan = async () => {
        if (plan && ['trial', 'buy', 'free'].includes(planTypes ? planTypes : '')) {
            let url = `/subscription/isplanexists`
            const res = await axios
                .post(url, {
                    price_id: plan,
                    planType: planTypes,
                })
                .then((res) => {
                    if (res.data.result) {
                        if (res.data.data.isplanexist) {
                            setPlanName(res?.data?.data?.planName)
                            if (planTypes === 'free') {
                                let cameraDisplayCount = res?.data?.data?.cameraLimitCount ?? 3
                                setMaxCameraCount(cameraDisplayCount)
                                // setIsFreePlanAvailable(res?.data?.data?.isSignup);
                                formik.setFieldValue("estimatedCameraCount", cameraDisplayCount)
                            }

                            if (planTypes === 'trial') {
                                let cameraDisplayCount = res?.data?.data?.trialCameraLimit ?? 50
                                // formik.setFieldValue('cameraCount', cameraDisplayCount);
                                setMaxCameraCount(cameraDisplayCount)
                                formik.setFieldValue("estimatedCameraCount", cameraDisplayCount)
                            }
                            formik.setFieldTouched('cameraCount', true)
                        } else {
                            navigate('/')
                        }
                    } else {
                        navigate('/')
                    }
                })
                .catch((error) => {
                    navigate('/')
                })
        } 
    }

    useEffect(() => {
        const params = new URLSearchParams(search)
        const types = params.get('type');
        // if (types == "free") {
        //     formik.setFieldValue("estimatedCameraCount", maxCameraCount?? 5)
        // } else if (types == "trial") {
        //     formik.setFieldValue("estimatedCameraCount", maxCameraCount?? 100)
        // }
        checkPlan()
    }, [plan,planTypes])

    return (
        <><div>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                onSubmit={formik.handleSubmit}
            >
                <div className='mb-10 text-center'>
                    <h1 className='text-dark mb-3'>Inquiry Form</h1>
                </div>

                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
                    <input
                        placeholder='Email'
                        type='email'
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        onChange={(e) => {
                            formik.setFieldValue('email', TrimAllSpaceTextRaw(e.target.value || ''))
                        }}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            //   { 'is-invalid': formik.touched.email && (formik.errors.email || isMailExist) },
                            //   {
                            //     'is-valid': formik.touched.email && !formik.errors.email && !isMailExist,
                            //   }
                        )}
                    />
                    {formik.touched.email && (formik.errors.email) && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                    {formik.errors.email}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>Company Name</label>
                    <input
                        placeholder='Company name'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('companyName')}
                        onChange={(e) => {
                            formik.setFieldValue('companyName', (e.target.value || ''))
                        }}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            //   { 'is-invalid': formik.touched.confirmEmail && formik.errors.confirmEmail },
                            //   {
                            //     'is-valid': formik.touched.confirmEmail && !formik.errors.confirmEmail,
                            //   }
                        )}
                    />
                    {formik.touched.companyName && formik.errors.companyName && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                                <span role='alert'>{formik.errors.companyName}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='row fv-row mb-7'>
                    <div className='col-xl-6 mb-7 mb-xl-0'>
                        <label className='form-label fw-bolder text-dark fs-6 required'>First Name</label>
                        <input
                            placeholder='First name'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('firstName')}
                            onChange={(e) => {
                                formik.setFieldValue('firstName', TrimAllSpaceTextRaw(e.target.value || ''))
                            }}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                // {
                                //     'is-invalid': formik.touched.firstName && formik.errors.firstName,
                                // },
                                // {
                                //     'is-valid': formik.touched.firstName && !formik.errors.firstName,
                                // }
                            )}
                        />
                        {formik.touched.firstName && formik.errors.firstName && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block text-danger'>
                                    <span role='alert'>{formik.errors.firstName}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-xl-6'>
                        {/* begin::Form group lastName */}
                        {/* <div className='fv-row mb-5'> */}
                        <label className='form-label fw-bolder text-dark fs-6 required'>Last Name</label>
                        <input
                            placeholder='Last name'
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('lastName')}
                            onChange={(e) => {
                                formik.setFieldValue('lastName', TrimAllSpaceTextRaw(e.target.value || ''))
                            }}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                // {
                                //     'is-invalid': formik.touched.lastName && formik.errors.lastName,
                                // },
                                // {
                                //     'is-valid': formik.touched.lastName && !formik.errors.lastName,
                                // }
                            )}
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block text-danger'>
                                    <span role='alert'>{formik.errors.lastName}</span>
                                </div>
                            </div>
                        )}
                        {/* </div> */}
                        {/* end::Form group */}
                    </div>
                </div>

                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>Phone Number</label>
                    <input
                        placeholder='Phone number'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('phoneNumber')}
                        onChange={(e) => {
                            formik.setFieldValue('phoneNumber', e.target.value)
                        }}
                        className={clsx(
                            'form-control form-control-lg form-control-solid ',
                        )}
                    />
                    {formik.touched.phoneNumber && (formik.errors.phoneNumber) && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                                <span role='alert'>
                                    {formik.errors.phoneNumber}
                                </span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='fv-row mb-7'>
                    <div className='form-group mb-5'>
                        <div className='d-flex align-items-center'>
                            <label className='form-label fw-bolder text-dark fs-6 w-300px required'>Estimated Camera Count</label>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <button
                                        className='btn btn-secondary custom-input-group-btn'
                                        type='button'
                                        onClick={() => {
                                            if (Number(formik.values.estimatedCameraCount) == 1) return
                                            if (Number(formik.values.estimatedCameraCount) > 1) {
                                                formik.setFieldValue('estimatedCameraCount', Number(formik.values.estimatedCameraCount) - 1)
                                            }
                                        }}
                                    >
                                        -
                                    </button>
                                </div>

                                <input
                                    placeholder='Camera count'
                                    type='text'
                                    inputMode='numeric'
                                    pattern='[0-9]*'
                                    autoComplete='off'
                                    name='cameraCount'
                                    onKeyUp={(e) => {
                                        if (e.key === '0') {
                                            e.preventDefault(); // Block the '0' key press
                                        }
                                    }}
                                    value={formik.values.estimatedCameraCount}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/

                                        if (re.test(e.target.value) || e.target.value === '') {
                                            if (planTypes === 'free' || planTypes == "trial") {
                                              if (e.target.value == "0") {
                                                formik.setFieldValue('estimatedCameraCount', 1);
                                              }
                                              else if (Number(e.target.value) <= Number(maxCameraCount)) {
                                                formik.setFieldValue('estimatedCameraCount', e.target.value)
                                              } else {
                                                formik.setFieldError(
                                                  'estimatedCameraCount',
                                                  `The maximum camera count is ${Number(
                                                    maxCameraCount
                                                  )} for the ${planTypes == "free" ? "free plan" : "trial"}`
                                                )
                                              }
                                            }
                                            else {
                                              if (e.target.value == "0") {
                                                formik.setFieldValue('estimatedCameraCount', "1")
                                              } else if (Number(e.target.value) >= 1000000) {
                                                formik.setFieldValue('estimatedCameraCount', "1000000")
                                              } else {
                                                formik.setFieldValue('estimatedCameraCount', e.target.value)
                                              }
                      
                                            }
                                          }
                                    }}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid text-center quantity w-50px',

                                    )}
                                />

                                <div className='input-group-append'>
                                    <button
                                        className='btn btn-primary custom-input-group-btn'
                                        type='button'
                                        onClick={() => {
                                            if (planTypes == "free" && formik.values.estimatedCameraCount >= maxCameraCount) return
                                            if (planTypes == "trial" && formik.values.estimatedCameraCount >= maxCameraCount) return
                                            if(Number(formik.values.estimatedCameraCount) >= 1000000) {
                                                console.log(formik.values.estimatedCameraCount);
                                                formik.setFieldValue('estimatedCameraCount', "1000000")
                                                return
                                              }
                                            formik.setFieldValue(
                                                'estimatedCameraCount', Number(formik.values.estimatedCameraCount) + 1
                                            )
                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>

                        {formik.touched.estimatedCameraCount && formik.errors.estimatedCameraCount && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block text-danger'>
                                    <span role='alert'>{formik.errors.estimatedCameraCount}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='notice d-flex bg-light-warning  rounded border-warning  border border-dashed mt-7 mb-9 p-3'>
                        <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-2tx svg-icon-warning  me-4'
                        />
                        <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                                {/* <h4 className='text-gray-900 fw-bolder'>Note:</h4> */}
                                <div className='fs-6 text-gray-900'>
                                    This is merely an estimate. {planTypes=="buy"&& " You will be billed based on your usage."}
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Ldy1v8pAAAAACl6HM5p4a5ZuFTkF9e7xVnYg6wC"
                    onChange={(e: any) => {
                        const recaptchaValue = recaptchaRef.current.getValue();
                        console.log({ recaptchaValue })
                        setToken(recaptchaValue)
                    }}
                    onExpired={() => { if (recaptchaRef.current) { recaptchaRef.current.reset() } }}
                />
                <div className='text-center mt-4'>
                    <button
                        type="submit"
                        // id='kt_sign_up_submit'
                        className=' btn btn-lg w-100 mb-5 btn-primary '
                        disabled={loading || !token}
                    >
                        {!loading && <span className='indicator-label'>Submit</span>}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Loading...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button
                        type="button"
                        className=' btn btn-lg w-100 mb-5 btn-danger '
                        onClick={()=>{
                            navigate("/")
                        }}
                    >
                        Back To Home
                    </button>
                </div>
            </form>
        </div>
        </>
    )
}

export default InquiryForm 