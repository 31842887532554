import { useState } from "react";
import {GeneralSettingAction} from "../actiontypes/generalSetting"

const initialState = {
    generalData:null,
    isGeneralSuccess:false,
    isGeneralLoading:false,
    isGeneralError:false
}

const GeneralReducer = (state=initialState, action) => {
    switch (action.type) {
        case GeneralSettingAction.isGeneralSuccess:
            return {...state, 
                generalData: action.payload, 
                isGeneralSuccess: true,
                isGeneralLoading: false,
                isGeneralError: false
            };
            break;
        case GeneralSettingAction.isGeneralReset:
            return {...state, 
                generalData: null, 
                isGeneralSuccess: false,
                isGeneralLoading: false,
                isGeneralError: false
            };
            break;    
        case GeneralSettingAction.isGeneralLoading:
            return {...state,
                isGeneralSuccess: false,
                isGeneralLoading: true,
                isGeneralError: false
            }
            break;
        case GeneralSettingAction.isGeneralError:
            return {...state,
                generalData: action.payload,
                isGeneralSuccess:false,
                isGeneralLoading:false,
                isGeneralError:true
            }
            break;
        default:
            return {...state}
            break;
    }
}

export default GeneralReducer


