/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, lazy, Suspense, FC} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
// import {Registration} from './components/Registration'

import {toAbsoluteUrl} from '../../plugins/helpers'
import {Auth0Login} from './components/auth0Login'
import {StripeWrapper} from '../../services/StripeWrapper'
import InquiryForm from '../../pages/InquiryForm'


const AuthLayout = () => {
 
  useEffect(() => {
  
   
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex align-items-center flex-column flex-column-fluid p-20 pb-lg-20 '>
        <a href='/' className='mb-15 mt-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/PurifAI_Logo-2.png')}
            className=' h-50px'
          />
        </a>

        <div className='w-lg-500px bg-white rounded shadow-sm p-5 p-lg-15 '>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
const ForgotPassword = lazy(() => import('./components/ForgotPassword'))
const ResendMail = lazy(() => import('./components/ResendEmail'))
const Registration = lazy(() => import('./components/Registration'))
const AuthPage = () => (  
  <Routes>
    <Route index element={
      <Auth0Login />
    } />
    <Route path="login" element={
      <Auth0Login />
    } />
    <Route element={<AuthLayout />}>
      <Route
        path='registration'
        element={
          <StripeWrapper>
            <Registration />
          </StripeWrapper>
        }
      />
      <Route path="inquiry-form" element={<InquiryForm/>} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='resend-mail' element={<ResendMail />} />
    </Route>
  </Routes>
)

export {AuthPage}
