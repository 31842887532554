import {API_PATH, CLN_PATH} from './apiPath'

const API_ENV =
  process.env.NODE_ENV && process.env.NODE_ENV === 'production'
    ? process.env.NODE_ENV
    : 'development'
// find api url from env
const Settings = {
  API_ROOT: API_PATH[API_ENV].API_ROOT,
  CLN_ROOT: CLN_PATH[API_ENV].API_ROOT,
  LAMDA_ROOT: API_PATH[API_ENV].API_LAMDA_ROOT
}

export default Settings