import {combineReducers, configureStore} from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import UserDetailsReducer from './reducer/account'
import ImpersonateDetailsReducer from './reducer/impersonate'
import MessageDetailsReducer from './reducer/message'
import ToolTipDetailsReducer from './reducer/tooltip'
import SocketReducer from './reducer/websocket'
import GeneralReducer from './reducer/generalSetting'
import FormsDataReducer from './reducer/FormErrorIndicator'
import HelpDataReducer from './reducer/help'
import OrganizationReducer from './reducer/organization'
import SiteReducer from './reducer/site'

const rootReducer = combineReducers({
  userdetails: UserDetailsReducer,
  messagedetails: MessageDetailsReducer,
  tooltipDetails: ToolTipDetailsReducer,
  impersonatesDetails: ImpersonateDetailsReducer,
  SocketReducer: SocketReducer,
  generalDetails: GeneralReducer,
  formsData:FormsDataReducer,
  helpValue:HelpDataReducer,
  organization: OrganizationReducer,
  sites: SiteReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})
