import {helpAction} from '../actiontypes/help'

export const success = (payload) => {
    return {
        type: helpAction.helpSuccess,
        payload: payload,
    }
}



export const helpDetails = (value) => (dispatch) => {
    try {
        dispatch(success(value))
    } catch (error) {
        console.error(error.message)
    }
}
