import {OrganizationAction} from "../actiontypes/organization"

const initialState = {
    organizationData:null,
    isOrganizationSuccess:false,
    isOrganizationLoading:false,
    isOrganizationError:false
}

const OrganizationReducer = (state=initialState, action) => {
    switch (action.type) {
        case OrganizationAction.isOrganizationSuccess:
            return {...state, 
                organizationData: action.payload, 
                isOrganizationSuccess: true,
                isOrganizationLoading: false,
                isOrganizationError: false
            };
            break;
        case OrganizationAction.isOrganizationLoading:
            return {...state,
                isOrganizationSuccess: false,
                isOrganizationLoading: true,
                isOrganizationError: false
            }
            break;
        case OrganizationAction.isOrganizationError:
            return {...state,
                organizationData: action.payload,
                isOrganizationSuccess:false,
                isOrganizationLoading:false,
                isOrganizationError:true
            }
            break;
        default:
            return {...state}
            break;
    }
}

export default OrganizationReducer


