const DomainUrl = ( subdomain = "") => {
    let url = "";
    if (subdomain && subdomain != '') {
        url = `https://${subdomain}.${process.env.REACT_APP_BASE_DOMAIN}`;
    }
    else {
        url = `https://auth.${process.env.REACT_APP_BASE_DOMAIN}`;
    }
    return url
}

export default DomainUrl