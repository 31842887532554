import axios from 'axios'
import Settings from '../apiConfig/globalConfig'
import axiosRetry from 'axios-retry';
const apiUrl = Settings.LAMDA_ROOT
const instance = axios.create({
  baseURL: `${apiUrl}`,
})

axiosRetry(instance, {
  retries: 2,
  retryCondition(error) {
    switch (error?.response?.status) {
      // case 500
      case 502:
      case 403:
        return true;
      default:
        return false;
    }
  },
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  }
});

let accessToken = ''

//Binder function to bind initial getAccesstokenSilenty() Instance
export const interceptorTokenBinderWithLamda = (getAccessTokenSilently: any) => {
  // Request interceptor for API calls
  instance.interceptors.request.use(
    async (config) => {
      try {
        config.headers.Origin = window.location.origin
        if (accessToken === '') {
          accessToken = await getAccessTokenSilently()
          config.headers.Authorization = `Bearer ${accessToken}`
        } else {
          config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
      } catch (error) {
        return config
      }
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Response interceptor for API calls
  instance.interceptors.response.use(
    (response) => {

      return response
    },
    async function (error) {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        accessToken = await getAccessTokenSilently()
        console.log(accessToken, "accessToken")
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
        return instance(originalRequest)
      }
      return Promise.reject(error)
    }
  )
}

export const baseAxios = axios.create({
  baseURL: `${apiUrl}`,
})

export default instance
