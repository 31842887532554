import {helpAction} from '../actiontypes/help'

const initialState = {
  stepTo:1
}

const HelpReducer = (state = initialState, action) => {
  switch (action.type) {
    case helpAction.helpSuccess:
      return {
        ...state,
        stepTo: action.payload,
      }
      break
    default:
      return {...state}
      break
  }
}

export default HelpReducer;
