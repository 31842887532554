import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { toAbsoluteUrl } from "../plugins/helpers";

export const Auth0VerificationLink: FC = () => {
    const [searchParams,setSearchParams] =useSearchParams();
    return (
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='d-flex align-items-center flex-column flex-column-fluid p-20 pb-lg-20 '>
          <a href='/' className='mb-15 mt-5'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/PurifAI_Logo-2.png')}
              className=' h-50px'
            />
          </a>

          <div className='w-lg-500px bg-white rounded shadow-sm p-5 p-lg-15 '>
            <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
              <div className='text-center'>
                <h1 className='fs-2hx fw-bolder mb-5'>Verify Your Account</h1>
              
               
                  <div
                    className='d-flex justify-content-evenly m-10 flex-wrap'
                    style={{gap: '10px'}}
                  >
                    {/* <button
                      className='btn btn-info btn-hover-scale w-150px'
                    >
                      Confirm Here
                    </button> */}
                    {
                 searchParams.get("url") &&
                 (<a className="btn btn-primary btn-hover-scale w-150px"href={searchParams.get("url") ?? "#"}>Confirm Here</a>)
            }
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}